<template>
  <div v-if="!this.$func.checkPermission('รายการถอนเงิน')" class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="alert alert-danger" role="alert">
            <strong>คุณไม่มีสิทธิ์เข้าถึงหน้านี้</strong>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <div class="d-flex flex-wrap">
              <h4 class="my-auto mr-2 mt-0">รายการการถอนเงิน</h4>
              <el-button
                type="primary"
                class="mb-2"
                @click="withdrawCredit()"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                สร้างรายการถอนเงิน
              </el-button>
              <el-button type="primary" class="mb-2" @click="pullBack(``)">
                <i class="fa fa-plus" aria-hidden="true"></i>
                ดึงเครดิตคืน
              </el-button>
              <el-button
                type="danger"
                size="default"
                class="mb-2"
                @click="pullBack(1)"
                ><i class="fa fa-minus" aria-hidden="true"></i> ตัดเครดิต</el-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5 col-md-4 col-lg-2 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="startDateHistory"
                    type="date"
                    placeholder="วันที่เริ่ม"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="getHistoryWithdraw()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
            <div class="col-sm-5 col-md-4 col-lg-2 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">ถึง</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="endDateHistory"
                    type="date"
                    placeholder="ถึง"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="getHistoryWithdraw()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 col-lg-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationHistory.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationHistory.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-5 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryHistory"
                aria-controls="datatables"
              />
              <button
                @click="getHistoryWithdraw()"
                type="button"
                class="btn btn-danger my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableDataHistory"
                border
                v-loading="loading"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="85" label="วัน/เวลา">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อ-สกุล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D10 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เลขบัญชี">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ธนาคาร">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D11 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ยอดเงิน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D5).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ยอดเงินก่อน">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D8).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ยอดเงินหลัง">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D9).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="140" label="ผู้ทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"> </span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="100" label="รายละเอียด">
                  <template v-slot="props">
                    <button
                      v-if="props.row.D7.length > 20"
                      class="btn btn-info btn-sm"
                      @click="qrshow(props ? props.row.D7 : '')"
                    >
                      <i class="fa fa-qrcode" aria-hidden="true"></i>
                    </button>
                    <span
                      v-else-if="props.row.D7 === '9'"
                      class="badge badge-danger"
                    >
                      แอดมินถอนมือ
                    </span>
                    <span v-else class="badge badge-warning">
                      {{ props.row.D7 }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromHistory + 1 }} to {{ toHistory }} of
                {{ totalHistory }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationHistory.currentPage"
              :per-page="paginationHistory.perPage"
              :total="paginationHistory.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <card>
          <div slot="header">
            <h4 class="m-0 mr-2 mt-0">ประวัติดึงยอดคืน</h4>
          </div>
          <div class="row">
            <div class="col-sm-5 col-md-4 col-lg-2 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">เริ่ม</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="startDatePullBack"
                    type="date"
                    placeholder="วันที่เริ่ม"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="getHistoryPullBack()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
            <div class="col-sm-5 col-md-4 col-lg-2 d-inline-block">
              <div class="d-flex mb-2">
                <span class="my-auto mr-2" style="width: 30px">ถึง</span>
                <fg-input class="m-0 w-100">
                  <el-date-picker
                    v-model="endDatePullBack"
                    type="date"
                    placeholder="ถึง"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    @change="getHistoryPullBack()"
                  >
                  </el-date-picker>
                </fg-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 col-lg-2 d-inline-block">
              <el-select
                class="select-default mb-3 w-100"
                v-model="paginationPullBack.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in paginationPullBack.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-5 col-lg-3 ml-auto d-flex mb-3">
              <el-input
                type="search"
                class="my-auto mr-2"
                placeholder="ค้นหา"
                v-model="searchQueryPullBack"
                aria-controls="datatables"
              />
              <button
                @click="getHistoryPullBack()"
                type="button"
                class="btn btn-danger my-auto"
              >
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                stripe
                style="width: 100%"
                :data="tableDataPullBack"
                border
                v-loading="loadingPull"
              >
                <el-table-column :min-width="75" label="ลำดับ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D1 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="85" label="Username">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D2 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ชื่อ-สกุล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D7 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เครดิต">
                  <template v-slot="props">
                    <span
                      v-html="
                        props
                          ? Number(props.row.D5).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                          : ''
                      "
                    >
                    </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="เหตผล">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D6 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="ผู้ทำรายการ">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D3 : ''"> </span>
                  </template>
                </el-table-column>
                <el-table-column :min-width="100" label="วันที่">
                  <template v-slot="props">
                    <span v-html="props ? props.row.D4 : ''"> </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ fromPullBack + 1 }} to {{ toPullBack }} of
                {{ totalPullBack }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="paginationPullBack.currentPage"
              :per-page="paginationPullBack.perPage"
              :total="paginationPullBack.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :visible.sync="modals.modalWithdrawCredit" width="30%">
      <span slot="title">
        <i class="fa fa-plus" aria-hidden="true"></i>
        ถอนเครดิต โดย ADMIN!
      </span>
      <div>
        <form>
          <div>
            <fg-input
              @change="getMemberByPhone(formWithdraw.phone)"
              v-model="formWithdraw.phone"
              type="text"
              placeholder="กรอก Username"
            >
              <span slot="label"> Username </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formWithdraw.name"
              type="text"
              placeholder="กรอกชื่อ-สกุล"
              disabled
            >
              <span slot="label"> ชื่อ-สกุล </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formWithdraw.credit_withdraw"
              type="number"
              placeholder="กรอกยอดเงิน"
              @click="formWithdraw.credit_withdraw = ''"
              :min="0"
            >
              <span slot="label"> ยอดเงิน </span>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitWithdraw()">
          ถอน
        </button>
        <button
          class="btn btn-danger"
          @click="closeModal('modalWithdrawCredit')"
        >
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalPullBack" width="30%">
      <span slot="title" :class="{ 'text-danger': withdrawCreditType === 1 }">
        <i
          class="fa"
          :class="{ 'fa-minus': withdrawCreditType === 1, 'fa-plus': withdrawCreditType !== 1 }"
          aria-hidden="true"
        ></i>
        {{ withdrawCreditType ? "ตัดเครดิต" : "ดึงเครดิต" }} โดย ADMIN
      </span>
      <div>
        <form>
          <div>
            <fg-input
              @change="getMemberByPhone(formWithdrawBack.phone)"
              v-model="formWithdrawBack.phone"
              type="text"
              placeholder="กรอก Username"
            >
              <span slot="label"> Username </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formWithdrawBack.name"
              type="text"
              placeholder="กรอกชื่อ-สกุล"
              disabled
            >
              <span slot="label"> ชื่อ-สกุล </span>
            </fg-input>
          </div>
          <div>
            <fg-input
              v-model="formWithdrawBack.credit_withdraw"
              type="number"
              placeholder="กรอกยอดเงิน"
              @click="formWithdrawBack.credit_withdraw = ''"
              :min="0"
            >
              <span slot="label"> ยอดเงิน </span>
            </fg-input>
          </div>
          <div>
            <fg-input label="เหตุผล">
              <textarea
                v-model="formWithdrawBack.note"
                class="form-control"
                placeholder="กรอกเหตุผล"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
        </form>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="loadingSubmit">
        <button class="btn btn-success mr-2" @click="submitWithdrawBack()">
          {{ withdrawCreditType ? "ตัดเครดิต" : "ดึงเครดิต" }}
        </button>
        <button class="btn btn-danger" @click="closeModal('modalPullBack')">
          ยกเลิก
        </button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="modals.modalQrcode" width="20%">
      <span slot="title">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Qrcode
      </span>
      <div>
        <qrcode :text="qrcodevalue" class="qrcode m-auto"></qrcode>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-danger" @click="qrhide()">ปิด</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  DatePicker,
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Dialog,
} from "element-ui";
import {
  Pagination as LPagination,
  FormGroupInput as FgInput,
} from "src/components/index";
import qrcode from "vue-qrcode-component";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {
    [Button.name]: Button,
    FgInput,
    [DatePicker.name]: DatePicker,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    qrcode,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      loading: true,
      loadingPull: true,
      loadingSubmit: false,
      startDateHistory: moment(new Date()).format("YYYY-MM-DD"),
      endDateHistory: moment(new Date()).format("YYYY-MM-DD"),

      startDatePullBack: moment(new Date()).format("YYYY-MM-DD"),
      endDatePullBack: moment(new Date()).format("YYYY-MM-DD"),

      formWithdraw: {
        phone: "",
        name: "",
        credit_withdraw: 0,
      },

      formWithdrawBack: {
        phone: "",
        name: "",
        credit_withdraw: 0,
        note: "",
      },

      modals: {
        modalQrcode: false,
        modalWithdrawCredit: false,
        modalPullBack: false,
        centered: false,
        custom: false,
        confirm: false,
      },
      selects: {
        simple: "",
        countries: [
          { value: "Bahasa Indonesia", label: "Bahasa Indonesia" },
          { value: "Bahasa Melayu", label: "Bahasa Melayu" },
          { value: "Català", label: "Català" },
          { value: "Dansk", label: "Dansk" },
        ],
      },

      qrcodevalue: "",

      paginationHistory: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },

      searchQueryHistory: "",

      tableDataHistory: [],

      paginationPullBack: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 50, 100, 200],
        total: 0,
      },

      searchQueryPullBack: "",

      tableDataPullBack: [],

      timeHistoryWithdraw: "",
      timeHistoryPullBack: "",

      withdrawCreditType: "",
    };
  },
  computed: {
    toHistory() {
      let highBound = this.fromHistory + this.paginationHistory.perPage;
      if (this.totalHistory < highBound) {
        highBound = this.totalHistory;
      }
      return highBound;
    },
    fromHistory() {
      this.getHistoryWithdraw();
      return (
        this.paginationHistory.perPage *
        (this.paginationHistory.currentPage - 1)
      );
    },
    totalHistory() {
      return this.paginationHistory.total;
    },

    toPullBack() {
      let highBound = this.fromPullBack + this.paginationPullBack.perPage;
      if (this.totalPullBack < highBound) {
        highBound = this.totalPullBack;
      }
      return highBound;
    },
    fromPullBack() {
      this.getHistoryPullBack();
      return (
        this.paginationPullBack.perPage *
        (this.paginationPullBack.currentPage - 1)
      );
    },
    totalPullBack() {
      return this.paginationPullBack.total;
    },
  },
  methods: {
    async getHistoryWithdraw() {
      this.loading = true;
      let offset =
        this.paginationHistory.perPage *
        (this.paginationHistory.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/withdrawHistory?search=${this.searchQueryHistory}&sort=&order=desc&offset=${offset}&limit=${this.paginationHistory.perPage}&action=&start_date=${this.startDateHistory}&end_date=${this.endDateHistory}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
      };

      clearTimeout(this.timeHistoryWithdraw);

      this.timeHistoryWithdraw = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loading = false;
            this.tableDataHistory = [];
            const data = res.data;
            if (data.res_code === "00") {
              this.tableDataHistory = data.res_result.rows;
              this.paginationHistory.total = data.res_result.total;
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }, 200);
    },
    async getHistoryPullBack() {
      this.loadingPull = true;
      let offset =
        this.paginationPullBack.perPage *
        (this.paginationPullBack.currentPage - 1);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/historypullback?search=${this.searchQueryPullBack}&sort=&order=desc&offset=${offset}&limit=${this.paginationPullBack.perPage}&action=&start_date=${this.startDatePullBack}&end_date=${this.endDatePullBack}`,
        headers: {
          authorization: this.$cookies.get("token"),
        },
        timeout: 10000,
      };

      clearTimeout(this.timeHistoryPullBack);

      this.timeHistoryPullBack = setTimeout(async () => {
        await axios
          .request(config)
          .then((res) => {
            this.loadingPull = false;
            this.tableDataPullBack = [];
            const data = res.data;
            if (data.res_code === "00") {
              this.tableDataPullBack = data.res_result.rows;
              this.paginationPullBack.total = data.res_result.total;
            }
          })
          .catch((error) => {
            this.loadingPull = false;
          });
      }, 1000);
    },
    async getMemberByPhone(phone) {
      this.formWithdraw.phone = "";
      this.formWithdrawBack.phone = "";

      let data = JSON.stringify({
        phone: phone,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.$hostname}/getmemberbyphone`,
        headers: {
          authorization: this.$cookies.get("token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      await axios
        .request(config)
        .then((res) => {
          let data = res.data;

          if (data.res_code === "00") {
            this.formWithdraw.phone = phone;
            this.formWithdrawBack.phone = phone;
            this.formWithdraw.name = data.res_result.fname;
            this.formWithdrawBack.name = data.res_result.fname;
          } else {
            this.formWithdraw.phone = "";
            this.formWithdrawBack.phone = "";
            Swal.fire("แจ้งเตือน", data.res_text, "error");
          }
        })
        .catch((error) => {});
    },
    withdrawCredit(type) {
      this.withdrawCreditType = type;
      this.formWithdraw.phone = "";
      this.formWithdraw.name = "";
      this.formWithdraw.credit_withdraw = 0;
      this.modals["modalWithdrawCredit"] = true;
    },
    submitWithdraw() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: `ยืนยันการถอนเงิน จำนวน: ${this.formWithdraw.credit_withdraw} ให้เบอร์: ${this.formWithdraw.phone}`,
        icon: "info",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            phone: this.formWithdraw.phone,
            credit_withdraw: this.formWithdraw.credit_withdraw,
          });

          this.loadingSubmit = true;

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/withdraw`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              this.loadingSubmit = false;
              let data = res.data;
              if (data.res_code === "00") {
                this.getHistoryWithdraw();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: "แจ้งถอนสำเร็จ",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
                this.tableDataHistory = [];
                this.getHistoryWithdraw();

                this.formWithdraw.phone = "";
                this.formWithdraw.name = "";
                this.formWithdraw.credit_withdraw = 0;

                this.modals["modalWithdrawCredit"] = false;
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              this.loadingSubmit = false;
            });
        }
      });
    },
    pullBack(type) {
      this.withdrawCreditType = type;
      this.formWithdrawBack.phone = "";
      this.formWithdrawBack.name = "";
      this.formWithdrawBack.credit_withdraw = 0;
      this.formWithdrawBack.note = "";
      this.modals["modalPullBack"] = true;
    },
    submitWithdrawBack() {
      if (
        !this.formWithdrawBack.phone ||
        !this.formWithdrawBack.credit_withdraw ||
        !this.formWithdrawBack.note
      ) {
        return Swal.fire("แจ้งเตือน", "กรุณากรอกข้อมูลให้ครบ", "warning");
      }

      Swal.fire({
        title: "แจ้งเตือน",
        text: `ยืนยันการ${
          this.withdrawCreditType === 1 ? "ตัดเครดิต" : "ดึงเครดิตคืน"
        } จำนวน: ${this.formWithdrawBack.credit_withdraw} ให้เบอร์: ${
          this.formWithdrawBack.phone
        }`,
        icon: "info",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let data = JSON.stringify({
            phone: this.formWithdrawBack.phone,
            credit_withdraw: this.formWithdrawBack.credit_withdraw,
            note: this.formWithdrawBack.note,
            remark: this.withdrawCreditType ? 1 : "",
          });

          this.loadingSubmit = true;

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${this.$hostname}/withdrawback`,
            headers: {
              authorization: this.$cookies.get("token"),
              "Content-Type": "application/json",
            },
            data: data,
          };

          await axios
            .request(config)
            .then((res) => {
              this.loadingSubmit = false;
              let data = res.data;
              if (data.res_code === "00") {
                this.getHistoryWithdraw();
                Swal.fire({
                  title: "แจ้งเตือน",
                  text: "ดึงเครดิตคืนสำเร็จ",
                  icon: "success",
                  timer: 1500,
                  showConfirmButton: false,
                });
                this.tableDataPullBack = [];
                this.getHistoryPullBack();
                this.formWithdrawBack.phone = "";
                this.formWithdrawBack.name = "";
                this.formWithdrawBack.credit_withdraw = 0;
                this.formWithdrawBack.note = "";
                this.modals["modalPullBack"] = false;
              } else {
                return Swal.fire("แจ้งเตือน", data.res_text, "error");
              }
            })
            .catch((error) => {
              this.loadingSubmit = false;
              return Swal.fire("แจ้งเตือน", error, "error");
            });
        }
      });
    },
    qrshow(value) {
      this.qrcodevalue = value;
      this.modals["modalQrcode"] = true;
    },
    qrhide() {
      this.modals["modalQrcode"] = false;
    },
    openModal(name) {
      this.modals[name] = true;
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    confirmClose() {
      Swal.fire({
        title: "แจ้งเตือน",
        text: "ต้องการยกเลิกการทำรายการ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonClass: "btn btn-danger",
        cancelButtonClass: "btn btn-warning",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ดำเนินการต่อ",
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.modals["modalWithdrawCredit"] = false;
          this.modals["modalPullBack"] = false;
        }
      });
    },
  },
  mounted() {},
  watch: {
    searchQueryHistory(newSearch, oldSearch) {
      this.searchQueryHistory = newSearch;
    },
  },
};
</script>
<style></style>
